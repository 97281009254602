import { applyMiddleware,  compose,createStore,} from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers/combineReducer';

const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    if (module.hot) {
    // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers/combineReducer.js', () => {
            const nextRootReducer = require('./reducers/combineReducer.js');
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
}