
export const imageUploadinLocal = true

export const BASEURL = 'https://super-admin.delcibo.co.uk'
// export const BASEURL = 'http://192.168.1.71:3002'

// export const S3BUCKETNAME = 'single-foodel-beta'
// export const S3BUCKETNAME = 'single-foodel-development'
export const S3BUCKETNAME = 'single-foodel-production'
// export const S3BUCKETNAME = 'single-foodel-local'

export const APIURL = `${BASEURL}/backend-api/`
// export const APIURL = `${BASEURL}/foodel_api/`

export const S3ImageUpload = imageUploadinLocal ? `${APIURL}uploads/` : `https://${S3BUCKETNAME}.s3.eu-west-2.amazonaws.com/uploads/`

export const languageArr = ['EN', 'SPA']
export const timezoneArr = ['Local Time', 'America/Los_Angeles', 'asia/kolkata', 'Europe/London']
export const dateFormatArr = ['YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM-YYYY']
export const timeFormatArr = ['12 Hours', '24 Hours']
export const firstDayOfWeekArr = ['Sunday', 'Monday']
export const currencyArr = ['INR', 'USD', 'GBP']
export const currencyPositionArr = ['Left', 'Right']
export const decimalSeparatorArr = ['Dot(.)', 'Comma(,)']
export const decimalArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export const weekendsArr = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const weekendsArrForPromotion = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const symbolsArr = ["e", "E", "+", "-", "."];

export const reasonCharLimit = 60;
export const stripeStatementLimit = 22
export const stripeShortStatementLimit = 10

export const URLSlug = '.foodel.it'

export const userRole = [
  {
    id: 1,
    role: 'customer'
  },
  {
    id: 2,
    // role: 'admin'
    role: 'restaurant'
  },
  {
    id: 3,
    role: 'admin'
  },
  {
    id: 4,
    role: 'driver'
  },
  {
    id: 5,
    role: 'user'
  }
]
export const pageManagePageList = ['About Us', 'Terms & Conditions', 'Privacy Cookies', 'Privacy Policy', 'Allergy']


export const PaymentType = [
  {
    id: 1,
    type: 'Cash'
  },
  {
    id: 2,
    type: 'Pay with PayPal'
  },
  {
    id: 4,
    type: 'Payment sense'
  },
  {
    id: 10,
    type: 'Viva Wallet'
  },
  {
    id: 11,
    type: 'Stripe Payment'
  },
  {
    id: 10,
    type: 'Viva Wallet Terminal'
  }
]

export const StatusforTransaction = [
  {
    id: 100,
    status: 'Pending Order.'
  },
  {
    id: 125,
    status: 'Order Cancelled.'
  },
  {
    id: 150,
    status: 'Order Accepted.'
  },
  {
    id: 200,
    status: 'Order Rejected.'
  },
  {
    id: 300,
    status: 'Driver Accepted.'
  },
  {
    id: 350,
    status: 'Ready to Collect.'
  },
  {
    id: 400,
    status: 'Delivered.'
  },
  {
    id: 500,
    status: 'Processing Refund.'
  },
  {
    id: 600,
    status: 'Partially Refunded.'
  },
  {
    id: 700,
    status: 'Fully Refunded.'
  },
]



export const paymentGetways = [
  { PaymentType: 1, name: 'Cash' },
  { PaymentType: 10, name: 'Viva' },
  { PaymentType: 4, name: 'PaymentSense' },
  { PaymentType: 11, name: 'Stripe' }
]