import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import ResetPassword from './jsx/pages/ResetPassword';
import { toast } from 'react-toastify';

const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    const { error, message } = useSelector((state) => state.commonData)

    return (<>
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
      {message
        ? error
          ?
          toast.error(message, {
            toastId: "customId",
            autoClose: 5000,
          }) :
          toast.success(message, {
            toastId: "customId",
            autoClose: 5000,
          })
        : ''}
    </>
    );
  }

  return ComponentWithRouterProp;
}



function App(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = localStorage.getItem('userDetails')

  useEffect(() => {
    if (userDetails) {
      checkAutoLogin(dispatch, navigate);
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      navigate(location.pathname)
    } else {
      console.log(window.location.href)
      let path = encodeURIComponent(window.location.href.split('/').pop())
      navigate({
        pathname: '/admin/login',
        search: path,
      });
    }
  }, []);

  if (props.isAuthenticated && (location.pathname == '/admin/login' || location.pathname == '/login')) {
    if (location.search) {
      navigate('/admin/' + decodeURIComponent(location.search.replace('?', '')))
    } else {
      navigate('/admin/restaurantlist')
    }
  }




  let routeblog = (
    <Routes>
      <Route path='/admin/login' element={<Login />} />
      <Route path='/forgotpassword' element={<ForgotPassword />} />
      <Route path='/resetPassword/:slug' element={<ResetPassword />} />
    </Routes>
  );


  if (props.isAuthenticated) {
    return (
      <>
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          <Index />
        </Suspense>
      </>
    );

  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
};


const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

// export default connect((mapStateToProps)(App)); 
export default withRouter(connect(mapStateToProps)(App));

